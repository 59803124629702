import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import cert from "./cert.png";

const gpt = [
    `Короче, смотрел я вчера фильм "Русские пацаны". Что я могу сказать - не понравилось. Конечно, кадры красивые, Москва крутая, много драк, погонь и всего такого. Но сюжет - просто никакой.

Такие стереотипные пацаны, все на одно лицо, все одинаковые. И мафия тоже, неправдоподобные персонажи, ничего интересного. Да и логика сюжета где-то потерялась - персонажи принимают нелогичные решения, никак не соответствуют реальности.

Хотя есть и свои плюсы. В фильме затронуты важные темы - дружба, солидарность, мафия. Это действительно актуальные вещи, о которых надо говорить. Но в целом, фильм так себе.

Ну что ж, я бы поставил этому фильму 4.5 из 10.`,
    `*Чебуречная

Сходил в чебуречную на Павелецком

Чувства очень смешанные. С одной стороны это явно не поход в топовую столовую, где блюда покрываются золотом, а официанты носятся в костюмах от Gucci.

С другой стороны, я вообще не понял, нахуй они спрашивают, какие ингредиенты ты хочешь в своих чебуреках, если они всегда одинаковые, как у всех других, кто туда ходит.`,
    `*Веганский плов

Сегодня я рискнул и заказал веганский плов в кафе. И, блядь, я не пожалел! Это было просто офигенно. Я чуть не взорвался от охуительного вкуса, который мне открылся. Как будто в моем рту происходило какое-то действие, в котором принимали участие главные герои - зернышки, овощи и специи. Мяса не было, а вкус был лучше, чем я когда-либо пробовал. Это был абсолютно новый опыт, который заставил меня задуматься о том, что на самом деле можно создать без мяса. Невероятно, какие блядские шедевры можно приготовить из растительных продуктов!`,
    `*Йогурты

Я тут понял, что упускал всю жизнь - йогурты! И я вам скажу, ребят, если вы не едите йогурты, то вы вообще не ебете, что такое настоящий вкус!

Это как пить бульон, только вместо чашечки ты ебашишь его в стаканчике. Невероятно крутое ощущение, когда хлопаешь на ходу йогурт с нейтральным вкусом и тут же запиваешь водой.

А если ты еще не пробовал йогурт с фруктовыми вкусами, то ты вообще какой-то инопланетянин. Я вот на днях купил йогурт со вкусом клубники, и это просто оргазм во рту, словами не передать.

Конечно, может быть, у вас нет привычки есть йогурты, но поверьте мне, оно того стоит. И еще - не думайте, что это дорогое удовольствие. Йогурты стоят копейки, а вкус - priceless!`,
    `*Алкоголь

Алкоголь: кто к нему не пристрастился, тот наверняка попробовал. Хмельное бренчание - это такая хрень, которую можно впихнуть в любую вечеринку и почувствовать себя живым. Но вот как всегда, все в меру. Чтобы не превратиться в вонючего бомжа, лучше контролировать свое потребление. После первых 2-3 стаканчиков все равно уже не важно, что пьешь, важно лишь то, чтобы настроение было отличным до конца вечеринки. Но это уже смотря с какой целью идешь на выпивку.

Если тебе за 20, а ты пьешь любую херь, то это уже немного тревожно. Неужели у тебя нет больше никаких интересов, кроме как сесть и запить свои проблемы? Вот смотри, алкоголь - это нормальное явление, если ты умеешь его контролировать. Но если ты уже обращаешься к нему как к костылю - пора задуматься. Ведь неужели ты настолько слаб, что не можешь решить свои проблемы самостоятельно?

Но все же, не будем забывать, что алкоголь может быть не только инструментом против скучной жизни, но и приятным дополнением к хорошей компании. Все же, за чашечкой вина в тихой атмосфере даже самые глубокие разговоры могут принять другой смысл. А за стаканчиком вискаря - так особенно.

Так что не забывайте о мере, и пейте с умом.`,
    `*Пизданутые англицизмы

Я тут на вечеринке был и услышал, как два парня рассуждают про использование английских слов в нашей разговорной речи. Один из них говорит: "Ну как ты можешь говорить на английском, если ты не знаешь даже своего родного языка?". Второй отвечает: "Брат, это же ведь круто, когда ты можешь сказать что-то на английском, и это звучит еще круче, чем на русском!".

И я согласен, но только до определенной степени. Знаете, что однажды произошло? Один мой друг, который сильно любит использовать англицизмы, попросил девушку принести ему "кофе с молоком". Девушка ушла, и через несколько минут вернулась с чашкой кофе, в которой было... ну... "молоко"! Ну а как еще? Она же не поняла, что ей принести, когда услышала такой заказ! Ну и мы тогда смеялись, что он сам себе наказал за такую неуклюжую речь.

Короче, я не против использования английских слов, но важно понимать, где и когда это уместно. Не надо делать из себя идиота, которого никто не поймет!`,
    `*🚀 Сегодня в Телеграме:

1. Представляете, нашел группу, где обсуждают способы, как обмануть охранников на стройке и украсть стройматериалы. Куча говна, но зато реальные советы от бывалых товарищей.
2. Также наткнулся на дискуссию "как правильно сбить кого-то на улице и не попасть в тюрьму". А ведь все так просто, нужно просто сказать, что это была самооборона.
3. В тренде - бандитская тематика. Самые популярные каналы про разборки, драки и наркотики. Слышал, что там еще и про ограбления рассказывают, но лично меня это не интересует.

P.S. Если кто-то поделится контактами хорошего наемного убийцы, дайте знать, очень нужен.`,
    `Короче, вы представляете себе, что чуваки настолько забитые, что стоят в -30 на катке и катаются, словно жизнь на кону. И не просто катаются, а там дизайнеры костюмов богатеют, тренеры рвут себе жопу, чтобы спортсмены оказались на вершине олимпийского пьедестала. А все для того, чтобы судьи, те самые судьи, которые сами никогда в жизни на коньках не стояли, поставили им высокую оценку. И так уже лет 100 делают, да и наверняка еще будут делать.`,
    `Честно говоря, нахуй секс. Почему бы не сесть за комп и не поиграть в Dark Souls? Не надо тратить кучу времени на ненастоящую мразь, которая сосет тебе член с пол оборота. Лучше бы еще на сцену вышла какая-то демонесса с вагиной на месте рта, а то их всегда не хватает. А еще лучше - виртуальная реальность! Там ты можешь выебать кого угодно, как угодно и сколько угодно раз, пока не сдохнут аккумуляторы. Так что секс - это для лохов, а мы играем в видеоигры и наслаждаемся виртуальными радостями.`,
    `*Говновынос

Ну, че, друзья, у нас тут новый член коллектива - говновынос! В любой работе найдется хоть один дегенерат, который не может смыть свои дерьмовые следы после себя в унитазе. Так что, давайте приветствовать нашего нового мазохиста!

А вы знаете, что эти дебилы думают? Что им за это платят? Это ж не нормально, чуваки, совсем не нормально. Иногда я хочу взять и намеренно нагадить на сиденье, чтоб этим задолбалам понятно было, как это неприятно наебывать после них.

Но вы знаете, что еще хуже? Это когда эти крысы убегают, не смыв свои зачатки. Ты заходишь в унитаз и думаешь: "о, Боже мой, что это за жуткая говноводная тварь протащилась здесь?". И потом, ты должен выбираться оттуда, пожелав этому куску говна все самого плохого.

Так что, друзья, я прошу вас: если вы хотите быть уважаемыми членами нашей команды, смывайте за собой свои жидкости и твердости. Это не так сложно, даже если у тебя дизентерия. Просто возьми бумажное полотенце и отрой этот говняный крюк в унитазе, а не оставляй его на ужин всех остальных. Спасибо, что пришли на мой тед ток.`,
];

const pashka = [
    `Если вдруг кто-то решил, что мало отдохнул, не забывайте:

1. 28 дней оплачиваемого отпуска
2. 8 дней новогодних
3. 9 мая, 4 ноября, 12 июня

Итого: 39 и я наверняка еще что-то забыл

В США, например, всего около 21 (отпуск там выдают на 15 дней + порядка 6 праздников)`,
    `Посмотрел "Аритмию". Мне фильм очень зашел: история, которую так или иначе проходил каждый из нас, застолья с колбасками "Премиум" за 150 рублей пачка и вином из коробки, грустное и веселое. А самое главное, и понял я это уже только после просмотра, что абсолютно глупая и надуманная причина, по которой герои все никак не могут найти гармонию в отношениях - это самое реалистичное, что есть в фильме.

В жизни же точно так же, какого толького глупого и надуманного дерьма с нами не происходит каждый день? 7/10`,
    `Сегодня у нас в офисе появилось какао! 

Я всегда был большим поклонником этого напитка, но в какой-то момент он почему-то из моей жизни пропал. И тут - опана! Я по старой памяти заварил себе 75% кипятка, 25% молока и получился просто божественный напиток! Буду тестировать разные пропорции, самая радикальная - 100% молока`,
    `Если вы при любом удобном поводе не пьёте куриный бульон - срочно исправляетесь. 

Особенно если он в чашечке и можно пить его на ходу. 

Если вы бульон не любите, просто держите в голове, что себестоимость порции всего 2-3,5 рубля. Поэтому ждать многого от него не нужно.`,
    `*Поел краба

Сегодня впервые поел краба, это просто отвал башки, фаршированные фаланги - это высший пилотаж. Тают во рту! Но это все хуйня, чему я по-настоящему удивился - это их камчатскому происхождению. В жизни бы не подумал, что у нас такие чудовища водятся`,
    `*Хороший инвестор - мертвый инвестор

Сегодня коллега рассказала об очень интересном исследовании, проведенного в стенах крупного инвестфонда. Суть примерно такая: самые лучшие показатели доходности показали портфели, о которых или забыли, или чьи владельцы были мертвы и не могли ничего изменить. Отсюда и пошла присказка "Хороший инвестор - мертвый инвестор".`,
    `Представьте себе на секунду, что люди со всеми мира сейчас собрались в одном месте, чтобы решить, кто из них быстрее всего перемещается по снегу на деревянных палках. И что так делают последние несколько тысяч лет.`,
    `Худшая идея в жизни - это побриться, начать носить на работу рубашки с брюками, бросить курить и перейти на правильное питание, пока 80% коллег в зарубежной поездке и на них все это вываливается разом 😩`,
    `*Просыпаться до будильника

Просыпаться по будильнику - это противоестественно, поэтому каждое утро, когда ты встаешь сам - это охуительное событие. Ходят легенды о людях, которые умеют просыпаться в нужное время без всяких свистелок и перделок, и таким людям я завидую 😴`,
    `Жутко, когда ты написал пост о том, как будешь сегодня адово бухать, и в ту же секунду на телефон твоего руководителя приходит пуш 🙀`,
];

function App() {
    const [isP, setIsP] = useState(false);
    const [used, setUsed] = useState([]);
    const [q, sq] = useState("");
    const [gs, sgs] = useState(0);
    const [score, setScore] = useState(0);
    const [nt, snt] = useState(0);
    const all = gpt.concat(pashka);

    const getNextQuestion = () => {
        const shrinked = all.filter((t) => !used.includes(t));
        const qq = shrinked[Math.floor(Math.random() * shrinked.length)];
        sq(qq);
        setIsP(pashka.includes(qq));
        setUsed([...used, qq]);
        sgs(0);
    };

    useEffect(() => {
        getNextQuestion();
    }, [nt]);

    return (
        <div
            className="App"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 50,
            }}
        >
            Угадано верно: {score}
            <br />
            <br />
            {gs === 0 ? (
                <div>
                    <div
                        style={{
                            maxWidth: 500,
                            textAlign: "left",
                            marginTop: 40,
                            marginBottom: 40,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: q
                                .split("\n")
                                .map((t) =>
                                    t.startsWith("*")
                                        ? `<b>${t.substring(1)}</b>`
                                        : t
                                )
                                .join("<br />"),
                        }}
                    />
                    <div style={{ marginTop: 40, marginBottom: 40 }}>
                        <button
                            style={{ marginRight: 30 }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (isP) {
                                    if (
                                        all.filter((t) => !used.includes(t))
                                            .length === 0
                                    ) {
                                        sgs(score >= 10 ? 3 : 4);
                                    } else {
                                        sgs(1); // wrong
                                    }
                                } else {
                                    if (
                                        all.filter((t) => !used.includes(t))
                                            .length === 0
                                    ) {
                                        sgs(score >= 9 ? 3 : 4);
                                        setScore(score + 1);
                                    } else {
                                        sgs(2); // right
                                        setScore(score + 1);
                                    }
                                }
                            }}
                        >
                            ChatGPT
                        </button>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                if (!isP) {
                                    if (
                                        all.filter((t) => !used.includes(t))
                                            .length === 0
                                    ) {
                                        sgs(score >= 10 ? 3 : 4);
                                    } else {
                                        sgs(1); // wrong
                                    }
                                } else {
                                    if (
                                        all.filter((t) => !used.includes(t))
                                            .length === 0
                                    ) {
                                        sgs(score >= 9 ? 3 : 4);
                                        setScore(score + 1);
                                    } else {
                                        sgs(2); // right
                                        setScore(score + 1);
                                    }
                                }
                            }}
                        >
                            Pavel O.
                        </button>
                    </div>
                </div>
            ) : gs === 1 ? (
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                    Неправильно!
                    <br />
                    <br />
                    <br />
                    <br />
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            getNextQuestion();
                        }}
                    >
                        Следующий!
                    </button>
                </div>
            ) : gs === 2 ? (
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                    Верно!
                    <br />
                    <br />
                    <br />
                    <br />
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            getNextQuestion();
                        }}
                    >
                        Следующий!
                    </button>
                </div>
            ) : gs === 3 ? (
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                    <br />
                    Ура, ты победил!
                    <br />
                    Видимо, нейросетям пока не удастся заменить гениального
                    автора.
                    <br />
                    <br />
                    <br />
                    <div style={{ maxWidth: 500 }}>
                        Дабы разнообразить скучные московские будни, я предлагаю
                        тебе попробовать себя в чем-то новом и травмоопасном.
                        Поэтому вот тебе сертификат на сёрфинг на искусственной
                        волне с инструктором (не буквально вместе с ним - он
                        стоит рядом и даёт советы). Я ходил туда несколько раз и
                        получил море удовольствия, надеюсь - ты получишь его
                        тоже :)
                        <br />
                        <br />С днём рождения!
                    </div>
                    <br />
                    <br />
                    <br />
                    <img src={cert} style={{ maxWidth: 500 }} />
                </div>
            ) : (
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                    Прости, но ты не смог набрать даже 10 очков :(
                    <br />
                    <br />
                    Попробуй ещё раз!
                    <br />
                    <br />
                    <br />
                    <br />
                    <button
                        onClick={() => {
                            setScore(0);
                            sgs(0);
                            setUsed([]);
                            snt(nt + 1);
                        }}
                    >
                        Попробовать!
                    </button>
                </div>
            )}
        </div>
    );
}

export default App;
